.cardimage-imagebg {
    z-index: -1;
    top: 0;
}
.cardimage-imagebg > img {
    transition: all 400ms;
}
.cardimage-mainct {
    --content-height: ;
}

.cardimage-mainct:hover > .cardimage-imagebg > img {
    transform: scale(1.2);
}
.cardimage-top-container {
    padding-bottom: 5%;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.029485) 33%,
        rgba(0, 0, 0, 0.0551155462184874) 47%,
        rgba(0, 0, 0, 0.0879726890756303) 67%,
        rgba(0, 0, 0, 0.11) 100%
    );
}
.cardimage-bottom-container {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.059485) 33%,
        rgba(0, 0, 0, 0.0951155462184874) 47%,
        rgba(0, 0, 0, 0.1379726890756303) 67%,
        rgba(0, 0, 0, 0.18) 100%
    );
}
.cardimage-content-p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cardimage-title-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px;
}
@media (max-width: 769px) {
    .cardimage-title-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 5px;
    }
}
.cardimage-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    transition: all 400ms;
    background: transparent;
}

.cardimage-title {
    transition: transform 0.3s ease;
    z-index: 1;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.059485) 33%,
        rgba(0, 0, 0, 0.0951155462184874) 47%,
        rgba(0, 0, 0, 0.1379726890756303) 67%,
        rgba(0, 0, 0, 0.18) 100%
    );
}

.cardimage-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    z-index: 0;
}

.cardimage-mainct:hover .cardimage-hover-content {
    transform: translateY(0);
}

.cardimage-mainct:hover .cardimage-hover-title {
    transform: translateY(calc(-1 * var(--content-height)));
    background: none;
}
.cardimage-mainct:hover .cardimage-info {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.059485) 33%,
        rgba(0, 0, 0, 0.0951155462184874) 47%,
        rgba(0, 0, 0, 0.1379726890756303) 67%,
        rgba(0, 0, 0, 0.18) 100%
    );
}

.gradient-1 {
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: linear-gradient(90deg, #a100ffff 0%, #215e8aff 100%);
}
.gradient-2 {
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
}
.gradient-3 {
    background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
}
.gradient-4 {
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}
.gradient-5 {
    background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
}
.gradient-6 {
    background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
}
.gradient-7 {
    background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%);
}
.gradient-8 {
    background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
}
.gradient-9 {
    background-image: linear-gradient(to top, #c71d6f 0%, #d09693 100%);
}
.gradient-10 {
    background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%);
}
.gradient-black {
    background-color: black;
}
